import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Games from "../Games.json";

const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

export default function Home() {
  const [isLoad, setIsLoad] = useState(true);
  const [randomGames, setRandomGames] = useState([]);

  useEffect(() => {
    const shuffledGames = shuffleArray([...Games]).slice(0, 20);
    setRandomGames(shuffledGames);
  }, []);

  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  return isLoad ? (
    <section className="flex items-center justify-center p-8" style={{ height: "calc(100vh - 300px" }}>
      <div className="loader"></div>
    </section>
  ) : (
    <section className="flex items-center justify-center p-8">
      <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 w-full">
        {randomGames.map((data, index) => (
          <div
            className="relative rounded-lg overflow-hidden shadow transform transition-shadow duration-300 hover:scale-105 bg-white dark:bg-gray-700 hover:shadow-lg "
            key={index}
          >
            <div className="relative">
              <img className="w-full object-cover" src={data.logo} alt={data.title} />
              <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent"></div>
            </div>

            <div className="absolute inset-0 flex items-center justify-center opacity-0 transition-opacity duration-300 hover:opacity-100">
              <Link
                to={`/single?slug=${data.slug}`}
                className="text-white text-xl"
              >
                <div className="bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 rounded-full p-4 flex items-center justify-center">
                  <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-6.207-3.695A1 1 0 007 8.305v7.39a1 1 0 001.545.832l6.207-3.695a1 1 0 000-1.664z" />
                  </svg>
                </div>
              </Link>
            </div>


            <div className="absolute bottom-0 left-0 w-full p-4  flex justify-center bg-gradient-to-t from-black via-transparent to-transparent">
              <span className="text-xl font-bold text-white">
                {data.title}
              </span>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}