import React, { useState } from "react";
import Loader from "./Loader";

export default function About() {
  const [isLoad, setIsLoad] = useState(true);

  setTimeout(() => {
    setIsLoad(false);
  }, 1000);

  return isLoad ? (
    <Loader />
  ) : (
    <>
      <section className="p-6 shadow-2xl bg-purple-300 rounded-lg m-3 text-lg">
        <h1 className="text-3xl font-bold text-center">About Us</h1>

        <p className="my-3">
          Welcome to <b>Onward Success!</b>
        </p>

        <p className="my-3">
          At Onward Success, we are dedicated to empowering individuals and
          organizations to achieve their goals and unlock their full potential.
          Our mission is to provide the tools, insights, and support needed for
          success in a rapidly changing world.
        </p>

        <h6 className="font-bold mb-3 mt-6">Our Story</h6>
        <p className="my-3">
          Founded in [Year], Onward Success was created to offer innovative
          solutions and strategies for personal and professional growth. We are
          passionate about helping our clients navigate their paths to success
          with confidence and clarity.
        </p>

        <h6 className="font-bold mb-3 mt-6">Our Team</h6>
        <p className="my-3">
          Our team is composed of experienced coaches, consultants, and industry
          experts who bring a wealth of knowledge and experience to our
          services. We are committed to delivering high-quality support and
          guidance to help you reach your goals.
        </p>

        <h6 className="font-bold mb-3 mt-6">Our Services</h6>
        <ul className="list-disc mx-3 space-y-1 list-inside">
          <li className="my-2">
            <b>Coaching and Consulting:</b> Personalized coaching and consulting
            services to help you overcome challenges and achieve your
            objectives.
          </li>
          <li className="my-2">
            <b>Training Programs:</b> A range of training programs designed to
            enhance skills and drive success.
          </li>
          <li className="my-2">
            <b>Resources and Tools:</b> Access to valuable resources and tools
            to support your growth and development.
          </li>
        </ul>

        <p className="my-3">
          Thank you for choosing Onward Success. We are excited to support your
          journey towards achieving your goals and realizing your potential.
        </p>
      </section>
    </>
  );
}
